import React from "react";
import styled from "styled-components";
import "./css/topHeader.css";

export const HeaderMain: React.FC = () => {
  return (
    <header id="topHeader" className="Header">
      <figure className="hd-logo">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/logo_black.png`}
          alt=""
        />
      </figure>
    </header>
  );
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoge: "",
    };
  }
  render() {
    return <HeaderMain />;
  }
}

const Css = styled.div``;

export default Header;
