import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import "../css/common.css";
import "../css/articleNostyle.css";
import { useLocation } from "react-router-dom";
import { studioData } from "../../src/studioData";

export default function Nostyle() {
  const location = useLocation();
  localStorage.setItem("magazinePath", location.pathname);

  let studioDetail = {};
  studioData.forEach((x) => {
    if (`/article/${x.path}` === location.pathname) {
      studioDetail = x;
    }
  });

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
      $("main").css({
        "background-image": "url(/assets/img/article_nostyle/bg.png)",
      });
    });
  }, []);

  return (
    <main id="nostyle">
      <div className="area1">
        <img class="pc" src="/assets/img/article_nostyle/hero-img.png" alt="" />
        <img
          class="sp"
          src="/assets/img/article_nostyle/hero-img-sp.png"
          alt=""
        />
      </div>
      <img class="line" src="/assets/img/article_nostyle/line.png" alt="" />

      <div className="area2 inner">
        <div className="box">
          <h3>
            ABOUT<span className="ttl">代表 小野 達也</span>
          </h3>
          <p>
            小学校から大学までずっと野球をやっていて、知人に勧められてスポーツクラブに就職しました。
            福岡県出身なので福岡県のどこかで働くのかと思っていたら兵庫県の西宮市に配属が決まり、そこで未経験から体操を教えることになりました。10年働いたときに会社でできることの限界を感じて退職し、ひとまず自分がやりたいことを書き出しました。子どもが好きだったので託児所であったり、高齢者の方やダイエット目的の女性が通うフィットネスジムであったり、カフェのような飲食店もやりたいとか、そのひとつにダンススタジオもあって。やりたいことは多かったので最終的には色々なことができるグループ会社を作りたいと思いました。
          </p>
        </div>
        <div className="box">
          <figure className="img-box">
            <img src="/assets/img/article_nostyle/img01.png" alt="" />
          </figure>
        </div>
      </div>

      <div className="area3 inner">
        <div className="box">
          <h3>
            STUDIO<span className="ttl">スタジオについて</span>
          </h3>
          <p>
            退職後にスポーツクラブで働いていたアルバイトのダンサーと再会し飲みに行く機会があって、そのときに今後やっていきたいことを話したんですね。その流れからダンススタジオやりましょうよってなって、まずはそこから始めようと思い大阪の茨木市にstudio
            No
            Styleをオープンしました。いざダンススタジオを始めたらすごく奥が深くて、他にも色々やりたいと思いつつも生半可な気持ちでできるものじゃないことが分かりました。ストリートダンスのジャンルだけでもたくさんあったり、健康やフィットネス目的のものであったり。最近ではミュージカルコース単独舞台公演など活動の場を拡げています。
          </p>
        </div>
        <div className="box">
          <figure className="img-box">
            <img src="/assets/img/article_nostyle/img02.png" alt="" />
          </figure>
        </div>
      </div>

      <div className="area4 inner">
        <h3>
          STYLES<span className="ttl">経営について</span>
        </h3>
        <p>
          ご縁があってガンバ大阪のチアダンスのスクールを任せてもらっていたり、「Nothing
          2
          Looz」というダンスの世界大会もやらせてもらっていて、予選を突破した人はフランスで行なわれる決勝に進めるんですけど、このイベントで日本のダンスシーンがもっと盛り上がればと思っています。
          今はスタジオの運営をしながら受付も自分でやっていて、よく誰かを雇うべきだとは言われますが、どうしても現場で働いていた経験が自分としては根源にあるので、子ども達の顔と名前ぐらいは覚えていたいと思っています。
        </p>
        <figure>
          <img src="/assets/img/article_nostyle/img03.png" alt="" />
        </figure>
      </div>

      <div className="area5 inner">
        <div className="box">
          <h3>
            INSTRUCTOR<span className="ttl">インストラクター</span>
          </h3>
          <p>
            スタジオの核はインストラクターだと思っていて、良い先生がいて初めて質の高いレッスンを提供できるので、スタジオには優秀な方々に集まっていただいています。茨木市で始めたときも大阪の中心じゃなくてもレベルの高いレッスンが受けられるような場所を提供したいという想いがあり、経営方針的にもその方が自分らしいのかなと思いました。在籍してくれているダンサーがずっとダンスを仕事として続けられるような環境も確保したいと思っていて、スタジオのレッスンを行なう以外にも外部へのインストラクター派遣事業など積極的に活動を行なっています。
          </p>
        </div>
        <div className="box">
          <figure className="img-box">
            <img src="/assets/img/article_nostyle/img04.png" alt="" />
          </figure>
        </div>
      </div>

      <div className="area6 inner">
        <h3>
          RECRUITMENT<span className="ttl">新規会員募集</span>
        </h3>
        <p>
          studio No
          Styleでは新規会員様を随時募集中しております。ストリートダンスはもちろん、ミュージカルクラスやバレエやフラダンス、フィットネスやダイエットを目的としたクラスやヨガのクラス、高齢者の方向けの健康体操教室など、すべてを網羅するぐらい豊富なクラスとレッスンをご用意しております。私はスタイルにこだわらないこともひとつの考えだと思っていて、子ども達にもやりたいことが見つからないと悩まず、目の前のことに熱中する時間を大切にしてほしいと思っています。何かに夢中になれる環境を提供できるよう日々楽しいレッスンを行なっておりますので、ご興味のある方はぜひお問い合わせください。
        </p>
        <figure>
          <img src="/assets/img/article_nostyle/img05.png" alt="" />
        </figure>
      </div>

      <div id="studio-detail">
        <div className="studio-box inner">
          <p className="studioName">{studioDetail.name}</p>
          {studioDetail.address && (
            <p className="address">
              <span>
                <img src="/assets/img/article_common/address.png" alt="" />
              </span>
              {studioDetail.address}
            </p>
          )}
          {studioDetail.tel && (
            <p className="tel">
              <span>
                <img src="/assets/img/article_common/tel.png" alt="" />
              </span>
              {studioDetail.tel}
            </p>
          )}
          {studioDetail.mail && (
            <p className="mail">
              <span>
                <img src="/assets/img/article_common/mail.png" alt="" />
              </span>
              {studioDetail.mail}
            </p>
          )}
          {studioDetail.map && (
            <iframe
              src={`https://maps.google.co.jp/maps?output=embed&q=${studioDetail.map}&z=16`}
              frameborder="0"
              scrolling="no"
            />
          )}
        </div>
        <div className="studio-btn">
          <a href={studioDetail.url} target="_blank" rel="noreferrer">
            <button className="studio-link">
              {studioDetail.name} HPはこちら
            </button>
          </a>
        </div>
      </div>
    </main>
  );
}
