import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import "../css/common.css";
import "../css/articleSol.css";
import { useLocation } from "react-router-dom";
import { studioData } from "../../src/studioData";

export default function Sol() {
  const location = useLocation();
  localStorage.setItem("magazinePath", location.pathname);

  let studioDetail = {};
  studioData.forEach((x) => {
    if (`/article/${x.path}` === location.pathname) {
      studioDetail = x;
    }
  });

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
      $("main").css({
        "background-image": "initial",
      });
      $(".area3").css({
        "background-image": "url(/assets/img/article_sol/area3-bg.png)",
      });
      $(".area6").css({
        "background-image": "url(/assets/img/article_sol/area6-bg.png)",
      });
    });
  }, []);

  return (
    <main id="sol">
      <div className="area1">
        <img class="pc" src="/assets/img/article_sol/hero-img.png" alt="" />
        <img class="sp" src="/assets/img/article_sol/hero-img-sp.png" alt="" />
      </div>

      <div className="area2 inner">
        <div className="box img-box">
          <img src="/assets/img/article_sol/img1.png" alt="" />
        </div>
        <div className="box">
          <h3>
            <span class="img-ttl">
              <img src="/assets/img/article_sol/ttl-01.png" alt="" />
            </span>
            <span class="ttl">ヨガについて</span>
          </h3>
          <p>
            スタジオ名の「SOL(ソル)」はスペイン語で太陽を表す言葉で、私達は「いつも心に太陽を」をモットーにしています。変化を続ける体と向き合いながら、社会の一員として、家族の一員として、自分のことをちゃんと認めていけるような、そのために頑張れるだけのエネルギーを与えられるようなヨガを提供していきたいと考えています。
          </p>
        </div>
      </div>

      <div className="area3">
        <div className="inner">
          <div className="box img-box">
            <img src="/assets/img/article_sol/img2.png" alt="" />
          </div>
          <div className="box">
            <h3>
              <span class="img-ttl">
                <img src="/assets/img/article_sol/ttl-02.png" alt="" />
              </span>
              <span class="ttl">代表 吉野 綾子</span>
            </h3>
            <p>
              東京でダンスの仕事をしていた頃、身体のメンテナンスのためにヨガスタジオに通うようになる。
              結婚を機に仕事を辞めるが、好きなことを仕事にしたいという思いが強くなり、大手のヨガスタジオに就職。
              妊娠・出産を経て1年ほどインストラクターの仕事を離れ、産前・産後のヨガや瞑想指導者の資格を取得する。
              子供を持ちながら仕事をすることの大変さを感じると共に、ヨガと関わりのある時間を持てることの幸せに改めて気付く。
            </p>
          </div>
        </div>
      </div>

      <div className="area4 inner">
        <h3>
          <span class="img-ttl">
            <img src="/assets/img/article_sol/ttl-03.png" alt="" />
          </span>
          <span class="ttl">スタジオについて</span>
        </h3>
        <p>
          海が好きなので3年前にバリでサップヨガの資格も取得もしました。様々な種類のヨガをしたいと考えましたが、そのためには色んなスタジオを転々とする必要がありました。1種類に絞らず色んなヨガができる場所を作りたいと思い、兵庫県明石市でHOT
          & YOGA studio SOLをスタートしました。
          最初は協力してくれる知り合いのインストラクターに声をかけました。ママのインストラクターも多いので、自身が妊娠・出産・子育ての中で感じた仕事との両立の大変さを思い出しながら、家庭での役割もきちんとこなせるような環境作りを心がけています。
        </p>
        <figure>
          <img src="/assets/img/article_sol/img3.png" alt="" />
        </figure>
      </div>

      <div className="area5 inner">
        <h3>
          <span class="img-ttl">
            <img src="/assets/img/article_sol/ttl-04.png" alt="" />
          </span>
          <span class="ttl">新規会員募集</span>
        </h3>
        <p>
          HOT & YOGA studio
          SOLでは新規会員様を募集中です。未経験の方でも気軽にヨガを楽しんでいただけるようレベルやニーズに合わせた豊富なレッスンメニューをご用意しております。大久保駅からのアクセスもよく、女性専用のヨガスタジオなので女性の方には安心してスタジオに通っていただけます。清潔なスタジオで気持ちの良い汗を一緒に流しましょう。忙しい日常の中で自分自身と向き合う時間はとても大切です。自分を認め、自分に自信を持つためのサポートをさせていただければと思います。まずは体験からお気軽にお越しください。
        </p>
      </div>

      <div className="area6">
        <div className="inner">
          <figure>
            <img src="/assets/img/article_sol/img5.png" alt="" />
          </figure>
        </div>
      </div>

      <div id="studio-detail">
        <div className="studio-box inner">
          <p className="studioName">{studioDetail.name}</p>
          {studioDetail.address && (
            <p className="address">
              <span>
                <img src="/assets/img/article_common/address.png" alt="" />
              </span>
              {studioDetail.address}
            </p>
          )}
          {studioDetail.tel && (
            <p className="tel">
              <span>
                <img src="/assets/img/article_common/tel.png" alt="" />
              </span>
              {studioDetail.tel}
            </p>
          )}
          {studioDetail.mail && (
            <p className="mail">
              <span>
                <img src="/assets/img/article_common/mail.png" alt="" />
              </span>
              {studioDetail.mail}
            </p>
          )}
          {studioDetail.map && (
            <iframe
              src={`https://maps.google.co.jp/maps?output=embed&q=${studioDetail.map}&z=16`}
              frameborder="0"
              scrolling="no"
            />
          )}
        </div>
        <div className="studio-btn">
          <a href={studioDetail.url} target="_blank" rel="noreferrer">
            <button className="studio-link">
              {studioDetail.name} HPはこちら
            </button>
          </a>
        </div>
      </div>
    </main>
  );
}
