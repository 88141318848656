import React from "react";
import { BrowserRouter, Router, Link, Route } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Archive from "./Archive";
import ArchiveFooter from "./ArchiveFooter";

import Yushinkai from "./article/Yushinkai";
import Genten from "./article/Genten";
import Sol from "./article/Sol";
import Nostyle from "./article/Nostyle";
import Littlemysore from "./article/Littlemysore";

const App = () => (
  <BrowserRouter>
    <div>
      <Route exact path="/" component={Home} />
      <Route exact path="/article/yushinkai" component={Yushinkai} />
      <Route exact path="/article/genten" component={Genten} />
      <Route exact path="/article/sol" component={Sol} />
      <Route exact path="/article/nostyle" component={Nostyle} />
      <Route exact path="/article/littlemysore" component={Littlemysore} />
    </div>
    <ArchiveFooter />
  </BrowserRouter>
);

const Home = () => {
  return (
    <div>
      <Header />
      <Archive />
      <Footer />
    </div>
  );
};

export default App;
