import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import Styles from "../css/articleGenten.css";
import { useLocation } from "react-router-dom";
import { studioData } from "../../src/studioData";

export default function Genten() {
  const location = useLocation();
  localStorage.setItem("magazinePath", location.pathname);

  let studioDetail = {};
  studioData.forEach((x) => {
    if (`/article/${x.path}` === location.pathname) {
      studioDetail = x;
    }
  });
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
      h1_box();
      $(window).resize(function () {
        h1_box();
      });
      function h1_box() {
        // h1の横幅を取得し、.box1/.bo2付与
        $("h1").each(function (index, element) {
          var h1_w = $(this).innerWidth();
          $(this).children(".box1,.box2").css("width", h1_w);
        });
      }

      // 背景
      $("main").css({
        "background-image": 'url("/assets/img/article_01/bg_01.gif")',
      });
      $(".area3-bg").css({
        "background-image": 'url("/assets/img/article_01/img02.png")',
      });
    });
  }, []);

  return (
    <main id="genten">
      <article>
        <section className="area1 inner-article-1">
          <figure>
            <img class="pc" src="/assets/img/article_01/img01.png" alt="" />
            <img
              class="sp"
              src="/assets/img/article_01/hero-img-sp.png"
              alt=""
            />
          </figure>
          <spna className="cercle"></spna>
        </section>

        <section className="area2 inner-article-1">
          <h1>
            GENTENについて
            <span className="box1"></span>
            <span className="box2"></span>
          </h1>
          <p>
            GENTENではヨガが特別なことではなく、あくまでも生活の一部になるようなレッスンを行なっています。
          </p>
          <p>
            食事と同様にヨガからも栄養を摂ることで、年齢を重ねても健やかに活きいきと自分の人生を生き切ることができます。ヨガを行なうことで起こる体の反応を見ながら、その人に合ったアドバイスをし、次の課題に向かって適切なトレーニングをしていきます。
          </p>
          <p>
            女性の体は変化し続けていて、心が乱れることもあります。ヨガを暮らしに取り入れることで心と体を調整する、GENTENはそのサポートができるようなヨガスタジオを目指しています。
          </p>
        </section>

        <section className="area3">
          <div className="hurf-wrap inner-article-1">
            <div className="box bg area3-bg"></div>
            <div className="box">
              <h1 className="white">
                代表 ムツミ
                <span className="box1"></span>
                <span className="box2"></span>
                <img src="/assets/img/article_01/txt_inst.png" alt="" />
              </h1>

              <p className="small white">
                ぎっくり腰の経験から自身の身体のメンテナンスのためトレーニングを開始しました。
                <br />
                その後エアロビクスインストラクターの資格を取得し、本格的にフィットネスの業界に進みます。スポーツクラブの社員を経て、ヨガのインストラクターをすることになりました。
                <br />
                外側の筋肉は鍛えている方でしたが、元々ヨガを始めるまでは内側の筋肉や柔軟性がなく、疲れも抜けにくかったです。
                <br />
                色々なスタジオのインストラクターをやらせていただく中で自分が本当にしたいヨガが明確になってきて、自分自身もそのヨガを続けながら周りにも共有していきたいという想いが強くなり大阪市西成区でヨガスタジオGENTENをスタートさせました。
              </p>
            </div>
          </div>
        </section>

        <section className="area4 inner-article-1">
          <h1>
            少人数だからこそのレッスン
            <span className="box1"></span>
            <span className="box2"></span>
          </h1>
          <div className="block1">
            <p>
              レッスンに来られる方の目的はさまざまで、その人によっても適切なトレーニングは違います。
            </p>
            <p>
              例えば産後の方は骨盤が緩んでいるため、運動をしても骨盤がしまらず良い効果につながっていないことがあります。産後のダイエット目的の方にはまずお腹を鍛え骨盤をしめるところからスタートするなど、一人ひとりに合わせたレッスンを行なっていきます。
            </p>
          </div>
          <div className="block2">
            <p>
              少人数制だからこそ全員に時間と労力をかけることができますし、それぞれの課題に合わせた提案ができるのもGENTENの良さです。時間も1レッスンたっぷり90分設けているので、初心者の方がついていけずに挫折してしまうようなこともありません。
            </p>
            <figure>
              <img src="/assets/img/article_01/img03.png" alt="" />
            </figure>
          </div>
        </section>

        <section className="area5 inner-article-1">
          <h1>
            クラス構成
            <span className="box1"></span>
            <span className="box2"></span>
          </h1>
          <div className="block1">
            <p>
              クラスは4つに分かれていて、土・風・水・椅子ヨガがあります。
              <br />
              土はベースとなる基礎のクラスで、緩めると鍛えるをバランス良く取り入れ心と体を整えます。
              <br />
              風は流れるようにポーズをつないでいき、頭と心の風通しも良くなるような、リフレッシュに最適なクラスです。
              <br />
              水はじっくり時間をかけてポーズを行ない、少ない動きで体を奥からほぐしていきます。
              <br />
              椅子ヨガは床に座るのがつらい方や普段デスクワークの合間にヨガを取り入れたい方などにおすすめです
            </p>

            <figure>
              <img
                src="/assets/img/article_01/img04.png"
                className="pc"
                alt=""
              />
              <img
                src="/assets/img/article_01/img04-smh.png"
                className="smh"
                alt=""
              />
            </figure>
          </div>
        </section>

        <section className="area6 inner-article-1">
          <div className="block1">
            <h1>
              新規生徒募集中
              <span className="box1"></span>
              <span className="box2"></span>
            </h1>
            <div className="block">
              <p>
                ヨガスタジオGENTENでは新しい生徒の方を随時募集中です。
                <br />
                20代から60代まで幅広い年齢層の女性が通っていて、ヨガを長く続けていただきやすいよう料金設定もかなり抑えたヨガスタジオです。
                <br />
                続けやすいポイントとしてはアクセスの良さも抜群で、天下茶屋駅から歩いてすぐのところにあります。
                <br />
                消毒したヨガマットやその他プロップスも無料でレンタルを行なっているので、少しだけヨガに興味があるぐらいの方でもお気軽に始めることができます。
                <br />
                日々の仕事で疲れている方、年齢と共に起こる体の変化にお悩みの方、ダイエットしたい方やヨガを始めてみたい方など、まずは体験からお気軽にご予約ください。
              </p>

              <figure>
                <img src="/assets/img/article_01/img05.png" alt="" />
              </figure>
            </div>
          </div>
          <spna className="cercle type-1"></spna>
          <spna className="cercle type-2"></spna>
        </section>

        <div className="area7 inner-article-1">
          <address>
            <p>
              <a>{studioDetail.name}</a>
            </p>
            {studioDetail.address && (
              <p className="map">
                <i className="fas fa-map-pin fa-fw"></i>
                <a>{studioDetail.address}</a>
              </p>
            )}
            {studioDetail.tel && (
              <p className="tel">
                <i className="fas fa-phone fa-fw"></i>
                <a>{studioDetail.tel}</a>
              </p>
            )}
            {studioDetail.mail && (
              <p className="mail">
                <i className="fas fa-paper-plane fa-fw"></i>
                <a>{studioDetail.mail}</a>
              </p>
            )}
            {studioDetail.map && (
              <iframe
                src={`https://maps.google.co.jp/maps?output=embed&q=${studioDetail.map}&z=16`}
                frameborder="0"
                scrolling="no"
              />
            )}
          </address>
        </div>

        <div className="hp-nav">
          <a href={studioDetail.url} target="_blank" rel="noreferrer">
            <button>
              <i className="fas fa-chevron-right"></i>
              {studioDetail.name} HPはこちら
            </button>
          </a>
        </div>
      </article>
    </main>
  );
}
