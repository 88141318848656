import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import "./css/articleFooter.css";

export default function ArchiveFooter() {
  return (
    <div id="articleFooter">
      <Link to="../../">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/logo_black.png`}
          alt=""
        />
      </Link>
    </div>
  );
}
