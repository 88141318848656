import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import "../css/common.css";
import "../css/articleLittlemysore.css";
import { useLocation } from "react-router-dom";
import { studioData } from "../../src/studioData";

export default function Littlemysore() {
  const location = useLocation();
  localStorage.setItem("magazinePath", location.pathname);

  let studioDetail = {};
  studioData.forEach((x) => {
    if (`/article/${x.path}` === location.pathname) {
      studioDetail = x;
    }
  });

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
      $("main").css({
        "background-image": "initial",
      });
      $(".area3,.area5").css({
        "background-image": "url(/assets/img/article_littlemysore/bg.png)",
      });
    });
  }, []);

  return (
    <main id="Littlemysore">
      <div className="area1">
        <img
          class="pc"
          src="/assets/img/article_littlemysore/hero-img.png"
          alt=""
        />
        <img
          class="sp"
          src="/assets/img/article_littlemysore/hero-img-sp.png"
          alt=""
        />
      </div>

      <div className="area2 inner">
        <div className="box">
          <h3>
            LITTLE MYSORE<span className="ttl">スタジオについて</span>
          </h3>
          <p>
            リトルマイソールアシュタンガヨガ北浜は「もうこの年齢だから」「何年やったから」そんな思い込みをどんどん捨てていき、まっさらになれる場所を目指しています。自分にはできないと思い込んで自分自身に呪いをかけてしまう人、それを取ることが私の役目だと考えています。
            生徒一人ひとりが自立している分、新規の方が来られたときに何から始めるかを丁寧に指導することも可能です。それを反復してもらい、さらにアドバイスし再調整を続けることで、やる気さえあれば誰でもできるようになります。体調やライフスタイルに合ったペースで長く続けていくことで必ず成長や変化を感じていけるヨガスタジオです。
          </p>
        </div>
        <div className="box">
          <figure className="img-box">
            <img src="/assets/img/article_littlemysore/img01.png" alt="" />
          </figure>
        </div>
      </div>

      <div className="area3">
        <div className="inner">
          <div className="box">
            <h3>
              OWNER<span className="ttl">竹内 友美</span>
            </h3>
            <p>
              前職は航空会社に勤めていて海外を飛び回る生活を送っていたので、気圧の変化や時差によるストレスを抱えていました。感情のコントロールが難しい環境で、何かよいものがないか調べていたところ知人がやっていたこともありヨガに出会います。そこから本格的にアシュタンガヨガを始めました。マイソールのプログラムを運営する先生に師事し、レッスンを受けるため東京にも通い、自宅での練習も続けました。アシュタンガヨガの本拠地であるインドのマイソールにも定期的に通い練習を続け、Authorized
              level
              2(正式指導者レベル２)の認定も受けました。現在は大阪で本格的なアシュタンガヨガを学べるヨガスタジオ、リトルマイソールアシュタンガヨガ北浜を運営しています。
            </p>
          </div>
          <div className="box">
            <figure className="img-box">
              <img src="/assets/img/article_littlemysore/img02.png" alt="" />
            </figure>
          </div>
        </div>
      </div>

      <div className="area4 inner">
        <h3>
          YOGA STYLE<span className="ttl">ヨガについて</span>
        </h3>
        <p>
          肉体的なアプローチが強く、そのイメージが先行しがちですが、あくまでも思考と感情の上書きです。打ち消すのでは無く上書きしていくことで、消しゴムで消したようにまっさらになる爽快感が練習後に訪れます。運動量は多いかもしれませんが、きちんとその人に合ったものを提供していくので安心してヨガを身につけていくことができます。
          伝統的なアシュタンガヨガですが、取り組む姿勢は人それぞれです。インドで学ぶことを目的とする人もいればリフレッシュしたいという目的の方もいます。その人の土台となる基礎的な部分を教えて、いずれは自宅でもひとりで練習できるようになることを目指します。
        </p>
        <figure>
          <img src="/assets/img/article_littlemysore/img03.png" alt="" />
        </figure>
      </div>

      <div className="area5">
        <div className="inner">
          <div className="box">
            <h3>
              LESSON<span className="ttl">レッスン</span>
            </h3>
            <p>
              基本的には自主練習ができるように指導していきますが、人によってアドバイスが必要な場合もあれば、言葉や動作で介入しない方が練習の質が保たれる場合には見守るだけのときもあります。レッスンに来られる方はみなさん勉強熱心なので、あくまでも自主性のサポートとして、その人に応じたアドバイスをするように心がけています。
              アドバイスする際の言葉も大事にしていて、感覚的なことが伝わらない人には具体的な手順を伝えて実行してもらいます。くり返した人は自然とできるようになりますし、それをひとりのときにもくり返す力、その力を身につけることで自分自身の自信につなげてほしいです。
            </p>
          </div>
          <div className="box">
            <figure className="img-box">
              <img src="/assets/img/article_littlemysore/img04.png" alt="" />
            </figure>
          </div>
        </div>
      </div>

      <div className="area6 inner">
        <h3>
          RECRUITMENT<span className="ttl">新規会員募集</span>
        </h3>
        <p>
          リトルマイソールアシュタンガヨガ北浜は新しいお客様の参加をお待ちしております。アシュタンガヨガを学びたいという気持ちがあれば体力や柔軟性に自信がなくても、その人に合ったペースで指導を行なっていきます。例えば怪我をしている人にはその痛みを我慢するのではなく、ヨガを楽しめるような練習を行なっていただきます。体調や怪我に合わせた体の使い方やパターンを提案するのは得意なので、その日の状態をお伝えください。私は運が良かったので、ヨガを始めたときに良い先生に出会えたり環境にすごく恵まれました。リトルマイソールアシュタンガヨガ北浜がみなさんにとってそのような場所になれば幸いです。
        </p>
        <figure>
          <img src="/assets/img/article_littlemysore/img05.png" alt="" />
        </figure>
      </div>

      <div id="studio-detail">
        <div className="studio-box inner">
          <p className="studioName">{studioDetail.name}</p>
          {studioDetail.address && (
            <p className="address">
              <span>
                <img src="/assets/img/article_common/address.png" alt="" />
              </span>
              {studioDetail.address}
            </p>
          )}
          {studioDetail.tel && (
            <p className="tel">
              <span>
                <img src="/assets/img/article_common/tel.png" alt="" />
              </span>
              {studioDetail.tel}
            </p>
          )}
          {studioDetail.mail && (
            <p className="mail">
              <span>
                <img src="/assets/img/article_common/mail.png" alt="" />
              </span>
              {studioDetail.mail}
            </p>
          )}
          {studioDetail.map && (
            <iframe
              src={`https://maps.google.co.jp/maps?output=embed&q=${studioDetail.map}&z=16`}
              frameborder="0"
              scrolling="no"
            />
          )}
        </div>
        <div className="studio-btn">
          <a href={studioDetail.url} target="_blank" rel="noreferrer">
            <button className="studio-link">
              {studioDetail.name}
              <br />
              HPはこちら
            </button>
          </a>
        </div>
      </div>
    </main>
  );
}
