export const studioData = [
  {
    path: "yushinkai",
    name: "勇真会",
    address: "〒839−0862 福岡県久留米市野中町428-4階",
    map: "福岡県久留米市野中町４２８",
    tel: "0942-45-8280",
    mail: "yushinkai01@yahoo.co.jp",
    url: "https://www.yushinkai-kurume.com/",
    genre: ["空手", "護身術"],
    area: ["福岡", "大牟田"],
    publish: true,
    slide: true,
  },
  {
    path: "genten",
    name: "GENTEN",
    address: "〒557-0052 大阪市西成区潮路1丁目1番22号 ボヌール潮路3階",
    map: "〒557-0052 大阪市西成区潮路1丁目1番22号",
    tel: "090-7555-7573",
    mail: "yoga.aru.kurashi@gmail.com",
    url: "https://genten.tech-studio.site/",
    genre: ["ヨガ"],
    area: ["大阪", "天下茶屋"],
    publish: true,
    slide: true,
  },
  {
    path: "sol",
    name: "HOT&YOGA studio SOL",
    address: "〒674-0051 兵庫県明石市大久保町大窪546-1プリマ・ステラ101.102",
    map: "〒674-0051 兵庫県明石市大久保町大窪546-1プリマ・ステラ",
    tel: "078-965-6955",
    mail: "info@sol-yoga.jp",
    url: "https://www.sol-yoga.jp/",
    genre: ["ヨガ"],
    area: ["兵庫", "明石"],
    publish: true,
    slide: true,
  },
  {
    path: "nostyle",
    name: "studio No Style",
    address: "大阪府茨木市永代町5-308 Socioいばらき 3F",
    map: "〒567-0816 大阪府茨木市永代町5-308",
    tel: "072-658-5633",
    mail: "info@studio-nostyle.com",
    url: "http://www.studio-nostyle.com/",
    genre: ["ダンス"],
    area: ["大阪", "茨木"],
    publish: true,
    slide: true,
  },
  {
    path: "littlemysore",
    name: "リトルマイソール アシュタンガヨガ北浜",
    address:
      "〒541-0046 大阪府大阪市中央区平野町２丁目５−１４ フクビル三休橋４０１",
    map: "〒541-0046 大阪府大阪市中央区平野町２丁目５−１４",
    tel: "",
    mail: "docodemo.yoga@gmail.com",
    url: "https://www.littlemysore.com/",
    genre: ["ヨガ"],
    area: ["大阪", "淀屋橋", "北浜"],
    publish: true,
    slide: true,
  },
];
