import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import "../css/common.css";
import "../css/articleYushinkai.css";
import { useLocation } from "react-router-dom";
import { studioData } from "../../src/studioData";

export default function Yushinkai() {
  const location = useLocation();
  localStorage.setItem("magazinePath", location.pathname);

  let studioDetail = {};
  studioData.forEach((x) => {
    if (`/article/${x.path}` === location.pathname) {
      studioDetail = x;
    }
  });

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
      $("main").css({
        "background-image": 'url("/assets/img/article_yushinkai/main-bg.png")',
      });
      $(".area2").css({
        "background-image": 'url("/assets/img/article_yushinkai/area2.jpg")',
      });
      $(".area5").css({
        "background-image": 'url("/assets/img/article_yushinkai/area4_bg.png")',
      });
    });
  }, []);

  return (
    <main id="yushinkai">
      <div className="area1">
        <img
          class="pc"
          src="/assets/img/article_yushinkai/hirobanner.png"
          alt=""
        />
        <img
          class="sp"
          src="/assets/img/article_yushinkai/hero-img-sp.png"
          alt=""
        />
      </div>

      <div className="area2">
        <div className="inner">
          <div className="ttl-box">
            <h3>勇真会　大牟田支部</h3>
            <img
              class="sub-ttl"
              src="/assets/img/article_yushinkai/ttl-01.png"
              alt=""
            />
          </div>
          <p>
            勇真会では空手やキックボクシングなど打撃系格闘技と柔術やコマンドサンボなど組み技系格闘技の指導をしています。
            <br />
            打撃を鍛えるだけでは護身の場合でも相手に怪我をさせてしまうかもしれませんが、組み技を取り入れることで怪我をさせずに相手を制圧することができます。一見関係が無さそうな打撃と組み技の両方を学ぶことで視野も拡がり、新たな発見や気付きにも繋がります。
            <br />
            一つひとつの動きにこだわり、常に新しいものを取り入れることで技は進化していきます。指導者側も変化を恐れず学び続けることで、共に成長できる道場を目指しています。
          </p>
        </div>
      </div>

      <div className="area3">
        <div className="inner">
          <div className="box">
            <img src="/assets/img/article_yushinkai/area3.png" alt="" />
          </div>
          <div className="box">
            <div className="ttl-box">
              <h3>怪我をしないための稽古</h3>
              <img
                class="sub-ttl"
                src="/assets/img/article_yushinkai/ttl-02.png"
                alt=""
              />
            </div>
            <p>
              道場ではまず急所を覚え、痛みを知るところからスタートします。少しずつ痛みに慣れ打たれ強さを身につけるのと同時に防御のための避ける練習をします。いきなりスパーリングはせず、徐々に段階を経て実践に移っていくことで恐怖心を無くし、安心して稽古が続けられます。怪我をしてしまうと本当は稽古に使えたはずの時間を治療に費やすことになり、無駄な時間を過ごすことにもなりかねません。勇真会では過去大きな怪我をした人もいませんし、個人のペースや個性にあった伸ばし方を意識しています。
            </p>
          </div>
        </div>
      </div>

      <div className="area4">
        <div className="inner">
          <div className="ttl-box">
            <h3>人間性の成長を目指す</h3>
            <img
              class="sub-ttl"
              src="/assets/img/article_yushinkai/ttl-03.png"
              alt=""
            />
          </div>
          <p>
            「お礼が言えない」「謝れない」「返事ができない」子どもが成長する過程で身につけていくべき人としての基礎、格闘技を通じて格闘技以外のことも伝えられるよう心がけています。
            稽古を続けることで忍耐力や集中力を高め、受験や就職活動のような人生において大変な場面でも壁を乗り越えていける精神力を養います。実際に保護者の方から運動能力だけでなく学力が上がったという話もよく耳にします。他のスポーツにも共通して言えることですが、身体を鍛えるだけでなく頭も鍛えてこそ勝てるのが武道だと考えています。
          </p>
        </div>
      </div>

      <div className="area5">
        <div className="inner">
          <div className="ttl-box">
            <h3>スタッフ</h3>
            <img
              class="sub-ttl"
              src="/assets/img/article_yushinkai/ttl-04.png"
              alt=""
            />
          </div>
          <p>
            空手の世界大会で優勝経験を持つ指導者がイチから丁寧に指導していきます。「強くなりたい」「ダイエットがしたい」訪れる人の目的はさまざまです。一人ひとりに合った練習方法で、その人にとって最良のトレーニングを提案しています。フィットネスが目的の方には畳1枚分のスペースでできるトレーニング、護身が目的の方には対人以外でも使える身のこなし方など、あらゆるニーズにお応えします。親子で通われる方もいて、中には子どもの付き添いで始めた保護者の方が本格的に格闘技に熱中していくこともあります。
          </p>
          <div className="staff-list">
            <figure>
              <img src="/assets/img/article_yushinkai/staff1.png" alt="" />
            </figure>
            <figure>
              <img src="/assets/img/article_yushinkai/staff2.png" alt="" />
            </figure>
            <figure>
              <img src="/assets/img/article_yushinkai/staff3.png" alt="" />
            </figure>
          </div>
        </div>
      </div>

      <div className="area6">
        <div className="inner">
          <div className="ttl-box">
            <h3>新規生徒募集中！</h3>
            <img
              class="sub-ttl"
              src="/assets/img/article_yushinkai/ttl-05.png"
              alt=""
            />
          </div>
          <p>
            勇真会では新規生徒の募集を行なっています。冷暖房完備の清潔な道場で快適に格闘技を学ぶことができ、1日無料体験も随時募集中です。更衣室も完備していますので、女性の方にも安心してお越しいただけます。駐車場もございますので、お越しの際はお気軽にお問い合わせください。道場の硬いイメージはなく、アットホームな雰囲気で稽古を行なっています。習い事に格闘技をお考えの保護者の方が安心してお子様を通わせられるよう、オンラインで稽古の様子を見ることできるアプリも導入しています。もちろん直接道場での見学もオッケーです。ご興味のある方はまず道場見学、無料体験からご予約お待ちしております。
          </p>

          <div id="studio-detail">
            <div className="studio-box">
              <p className="studioName">{studioDetail.name}</p>
              {studioDetail.address && (
                <p className="address">
                  <span>
                    <img src="/assets/img/article_common/address.png" alt="" />
                  </span>
                  大牟田支部 : 〒836-0056 福岡県大牟田市姫島町７−１
                  <br />
                  本部 : 〒839−0862　福岡県久留米市野中町４２８−４階
                </p>
              )}
              {studioDetail.tel && (
                <p className="tel">
                  <span>
                    <img src="/assets/img/article_common/tel.png" alt="" />
                  </span>
                  {studioDetail.tel}
                </p>
              )}
              {studioDetail.mail && (
                <p className="mail">
                  <span>
                    <img src="/assets/img/article_common/mail.png" alt="" />
                  </span>
                  {studioDetail.mail}
                </p>
              )}
              {studioDetail.map && (
                <iframe
                  src={`https://maps.google.co.jp/maps?output=embed&q=${studioDetail.map}&z=16`}
                  frameborder="0"
                  scrolling="no"
                />
              )}
            </div>
            <div className="studio-btn">
              <a href={studioDetail.url} target="_blank" rel="noreferrer">
                <button className="studio-link">
                  {studioDetail.name} HPはこちら
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
