import React, { useEffect } from "react";
import $ from "jquery";
import { studioData } from "./studioData";

export default function ThreeSlide() {
  useEffect(() => {
    $(function () {});
  }, []);

  return (
    <div className="ThreeSlide big-banner-wrap inner-1">
      <div id="loader">
        <div className="spring-spinner">
          <div className="spring-spinner-part top">
            <div className="spring-spinner-rotator"></div>
          </div>
        </div>
      </div>

      <div id="big-banner" className="big-banner">
        <div className="slider js-slider">
          <div className="slider__inner js-slider__inner"></div>
          {studioData.map((studio) => {
            const ary = studio.area.concat(studio.genre).join("/");
            return (
              studio.slide && (
                <a className="slide js-slide" href={`./article/${studio.path}`}>
                  <div className="slider__text js-slider__text">
                    <div className="slider__text-line js-slider__text-line">
                      <div>
                        {studio.name} {ary}
                      </div>
                    </div>
                  </div>
                </a>
              )
            );
          })}
          <nav className="slider__nav js-slider__nav">
            {studioData.map((studio, i) => {
              const index = (Array(2).join("0") + (i + 1)).slice(-2);
              return (
                studio.slide && (
                  <div className="slider-bullet js-slider-bullet">
                    <span className="slider-bullet__text js-slider-bullet__text">
                      {index}
                    </span>
                    <span className="slider-bullet__line js-slider-bullet__line"></span>
                  </div>
                )
              );
            })}
          </nav>
          <div className="scroll js-scroll">Scroll</div>
        </div>
      </div>
    </div>
  );
}
