import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import ThreeSlide from "./threeSlide";
import "./css/topArchive.css";
import { useLocation } from "react-router-dom";
import { studioData } from "./studioData";

export default function Archive() {
  const location = useLocation();

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
    if (
      localStorage.getItem("magazinePath") !== "/" &&
      localStorage.getItem("magazinePath") !== null
    ) {
      localStorage.setItem("magazinePath", location.pathname);
      window.location.reload();
    }
    localStorage.setItem("magazinePath", location.pathname);
  }, []);

  return (
    <Css>
      <main id="topArchive">
        <ThreeSlide />
        <article className="Archive">
          {studioData.map((studio) => {
            return (
              studio.publish && (
                <section>
                  <Link to={`/article/${studio.path}`}>
                    <figure>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/archive/${studio.path}.jpg`}
                        alt=""
                      />
                    </figure>
                    <h1>{studio.name}</h1>
                    <ul>
                      {studio.genre.map((genre) => {
                        return <li>{genre}</li>;
                      })}
                      {studio.area.map((area) => {
                        return <li>{area}</li>;
                      })}
                    </ul>
                  </Link>
                </section>
              )
            );
          })}
        </article>
      </main>
    </Css>
  );
}

const Css = styled.div`
  /* common.css */
`;
